import { Container } from "./styles";
import { Hero } from "../Hero/Hero";
import { About } from "../About/About";
import { Contact } from "../Contact/Contact";
import { Project } from "../Project/Project";
import {Team} from "../Team/Team";
import {Service} from "../Service/Service";


export function Main() {
  return (
    <Container>
      <Hero></Hero>
      <About></About>
      <Project></Project>
      <Service></Service>
      {/*<Events></Events>*/}
      <Team></Team>
      <Contact></Contact>
    </Container>
  );
}