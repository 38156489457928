import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --pink: #E31F71;
    --black: #212121;
    --green: #23ce6b;
    --blue: #016fb9;
    --purple: #17726d;
    --tile-bg: #2b2b2b;
    --tile-text: #fff;
    --tile-hover-bg: var(--purple);
    --header-color: var(--blue);
    --link-color: #ffff;
    --link-border: var(--green);
    --link-hover-color: var(--green);
    --button-hover-bg: #313131;
    --pop-up-bg: lightgrey;
    --pop-up-text: black;
    --chat-bg: #212121;
    scroll-padding-top: 10rem;

    .no-scroll {
  overflow: hidden;
  }

    &.light {
      body {
        transition: 0.5s;
        background-color: #f5f5f5;
        color: var(--black);
      }

      .logo {
        color: var(--black);
      }

      header.header-fixed {
        transition: 0.5s;
        background-color: #f5f5f550;
        a {
          transition: 0.5s; 
          color: black;
        }
        .menu,
        .menu:before,
        .menu:after {
          background-color: var(--black);
        }
        .menu.active {
          background-color: rgba(255, 255, 255, 0);
        }
      }

      footer.footer {
        transition: 0.5s;
        background-color: rgba(0, 0, 0, 0.1);
        color: var(--black);
      }

      form {
        input,
        textarea {
          transition: 0.5s;
          border: solid 1px var(--black);
          color: var(--black);
          &::placeholder {
            transition: 0.5s;
            color: var(--black);
          }
        }
      }

      /* Light mode styles for project tiles */
      --tile-bg: #E2E2E2;
      --tile-text: black;
      --tile-hover-bg: lightgrey;
      --header-color: darkblue;
      --link-color: black;
      --link-border: grey;
      --link-hover-color: darkgrey;
      --button-hover-bg: darkgrey;
      --pop-up-bg: #2b2b2b;
      --pop-up-text: #fff;
      --chat-bg: darkgrey;
    }
  }

  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
  }

  body, input, textarea, button {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  button, .button {
    border: none;
    cursor: pointer;
    background-color: var(--green);
    color: #FFFF;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  button:disabled, .button:disabled {
    filter: brightness(0.8);
    cursor: not-allowed;
  }

  .logo {
    font-size: 3rem;
    color: #FFFF;
  }

  /* Hide the webkit scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
`;
