import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

export function Service() {
  const projects = [
    {
      title: "Generative AI Chat Service",
      description: "Enhance your customer interactions with Ewin Lab's Generative AI Chat Service. We help our clients to deliver personalized, engaging conversations that resonate with their audience. Whether addressing inquiries, providing support, or driving sales, our chat service transforms customer engagement, ensuring every interaction is meaningful, personalized and effective.",
      technologies: "https://farith.raaf.solutions/#chat"
    },
    {
      title: "Face Authentication System",
      description: "Ewin Lab's Advanced Face Auth service ensures the security of your application and infrastructure by providing robust access control. Our technology is designed to meet the needs of industries seeking automation in their processes. With our service's seamless integration and high accuracy, you can enhance your user experience while maintaining the highest standards of privacy and security.",
      technologies: ""
    },
    {
      title: "AI Supervision System",
      description: "Elevate your operational efficiency with Ewin Lab's AI Supervision System. Our advanced AI solution enables automated monitoring oversight and reporting across various processes, ensuring real-time decision-making and issue detection. From quality control to security management, this system provides unmatched precision, helping you optimize workflows while reducing human error and improving productivity.",
      technologies: ""
    },
  ];

  return (
    <Container id="service">
      <h2>Our Services</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <ScrollAnimation key={index} animateIn="fadeInUp">
            <div className="project">
              <header>
                <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#17726d" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> 
                  <title>Folder</title> 
                  <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> 
                </svg>
              </header>
              <div className="body">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
              <footer>
                <ul className="tech-list">
                  {project.technologies ? (
                    <li>
                      <a 
                        href={project.technologies} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: '#17726d', textDecoration: 'underline' }}
                      >
                        Click here for demo
                      </a>
                    </li>
                  ) : (
                    <li>Demo will be available soon</li>
                  )}
                </ul>
              </footer>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
