import styled from "styled-components";

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  justify-content: space-between; 
  gap: 8rem;
  background: rgba(0,0,0,0);
  .hero-text{

    & > p{
      font-size: 1.8rem;
    }
    h1{
      font-size: 10rem;
    }

    h3{
      color:var(--purple);
      margin: 1rem 0;
      font-size: 2rem;
    }

    img{
      width: 20%
    }

    
    p.small-resume {
      margin-bottom: 5rem;
    }
  }
// New added
  .social-media{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top:5rem;
    padding-left:1rem;

    img,span{
      font-size: 3rem;
      width: 3.5rem;
    }
  }


  .button{
    margin-top: 5rem;
    padding: 1.4rem 6rem;
    background: var(--purple)
  }

  .hero-image{
    img{
      max-width: 600px;
    }
  }
  @media(max-width: 1080px){
    display: block;
    margin-top: 10%;
    .hero-text{

      h1{
        font-size: 5rem;
      }
    }
    
    .hero-image{
      display: none;
    }
  }

  @media(max-width: 960px){
    display: block;
    padding-top: 0%;
    .hero-text{

      h1{
        font-size: 5rem;
      }
      h2{
        display: none;
      }
    }    
    img{
        width: 10%;
      }

    .hero-image{
      display: none;
    }
  }

  @media(max-width: 600px){
    margin-top: 10%;
  }
  @media(max-width: 480px){
    margin-top: 45%;
  }
`