import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 18rem;
  position: relative; /* Ensure it's properly positioned */

  h2 {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
    position: relative; /* Adjust if needed */
  }

  .events-container {
    overflow-x: auto;
    white-space: nowrap;
  }

  .events {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;

  }
  
  .event {
    min-width: 300px;
    padding: 2rem 1.8rem;
    background-color: var(--light);
    border-radius: 1.2rem;
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    color: var(--tile-text);

    &:hover {
      transform: translateY(-5px);
      background-color: var(--tile-bg);
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--header-color);
      margin-bottom: 2rem;
    }

    .event-image {
      width: 100%;
      height: 350px;
      object-fit: cover;
      border-radius: 0.8rem;
      margin-bottom: 1.5rem;
    }

    h3 {
      margin-bottom: 1.5rem;
    }

    p {
      letter-spacing: 0.12rem;
      margin-bottom: 1.5rem;
    }

    span {
      font-weight: bold;
      color: var(--highlight-color);
    }
  }

    .social-media{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding-top:1rem;

    img,span{
      font-size: 3rem;
      width: 3.5rem;
    }
  }

  @media (max-width: 960px) {
    .events {
      gap: 1.5rem;
    }

    .event {
      min-width: 260px;
    }

    .event-image {
      height: 180px;
    }
  }
    
@media (max-width: 740px) {
  .events-container {
    display: flex;
    overflow-x: scroll; /* Allow horizontal scroll */
    -webkit-overflow-scrolling: touch; /* For smoother scrolling on mobile */
  }

  .events {
    display: flex; /* Flex layout for horizontal scrolling */
    gap: 1.5rem;
    width: 100%; /* Ensure it occupies the full width */
  }

  .event {
    flex: 0 0 100%; /* Each event takes full width */
    scroll-snap-align: center; /* Snap to the center when scrolling */
  }

  .event-image {
    height: 160px; /* Adjust image height */
  }
}


`;
