import { Container } from './styles'
import linkedin from '../../assets/linkedin.svg'
import gmail from '../../assets/gmail.svg'

export function Footer() {
  return (
    <Container className="footer">
      <div className="footer-content">
        <div className="social-media">
          <a
            href="https://www.linkedin.com/company/ewin-labs/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="Linkedin" />
          </a>
          <a
            href="mailto:admin@ewinlabs.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={gmail} alt="Gmail" />
          </a>
        </div>

        <p>&copy; {new Date().getFullYear()} Ewin Labs. All rights reserved.</p>

       {/* <div className="footer-links">
          <a href="/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          <a href="/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </div> */}
      </div>
    </Container>
  )
}
