import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import farith from '../../assets/me.png';
import Rishe from '../../assets/Rishe.jpg';
import Kasif from '../../assets/kasif.jpg';
import Angu from '../../assets/angu.jpg';
import Dhanshree from '../../assets/dhp.jpg';
import Alagu from '../../assets/ala.png';
import Guhan from '../../assets/guh.jpg';
import linkedin from '../../assets/linkedin.svg'

export function Team() {
  const events = [
    {
      title: "Abdulfarith R A",
      role: "Chief Executive Officer",
      image: farith, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/abdul-farith-0580381a5"
    },
    {
      title: "Rishenath E",
      role: "Chief Operating Officer",
      image: Rishe, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/rishenath-e-83a33b2a4"
    },
    {
      title: "Abdul Kasif R A",
      role: "Chief Technology Officer",
      image: Kasif, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/abdul-kasif-20b9a6315"
    },
    {
      title: "Dhanshree N J K",
      role: "Chief Product Officer",
      image: Dhanshree, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/dhanshree-kumaresh-65b24b24a"
    },
    {
      title: "Anguraj S",
      role: "Chief Financial Officer",
      image: Angu, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/anguraj-s-4ab4b2218"
    },
    {
      title: "Guhan S",
      role: "Chief Marketing Officer",
      image: Guhan, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/guhan-s-66214022a/"
    },
    {
      title: "Alagupariksit A",
      role: "Chief Development Officer",
      image: Alagu, // Replace with actual image path
      linkedin: "https://www.linkedin.com/in/alagu-pariksit-a-482b72274"
    },
  ];

  return (
    <Container id="team">
      <h2>Meet the Team</h2>
      <div className="events-container">
      <div className="events">
        {events.map((event, index) => (
          <ScrollAnimation key={index} animateIn="fadeInUp">
            <div className="event">
              <img src={event.image} alt={event.title} className="event-image" />
              <div className="body">
                <h3>{event.title}</h3>
                {/*<p>{event.description}</p>*/}
                <span>{event.role}</span>
              </div>  
              <div className="social-media"><a
                    href={event.linkedin}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={linkedin} alt="Linkedin" />   
                  </a>
                </div>
            </div>
          </ScrollAnimation>
        ))}
      </div>
      </div>
    </Container>
  );
}
