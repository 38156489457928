import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

export function Project() {
  // Define an array of project data containing project details and technologies
  const projects = [
    {
      title: "Meva - Premium Fish Quality Assessment and Delivery Solution",
      description: "A state-of-the-art solution from Ewin Labs for precise fish quality assessment and seamless delivery. Our application utilizes advanced AI algorithms to deliver real-time, accurate evaluations of fish freshness and quality. By integrating cutting-edge image processing technology, Meva ensures consumers receive unparalleled insights and access to top-quality seafood. We Elevate your seafood experience with Our commitment to excellence and innovation in assessment and delivery.",
      technologies: ["React Native","Java", "DCNN", "Node.js", "Firebase"]
    },
    {
      title: "Ewin Chat - Advanced LLM Chat Solution",
      description: "A chat application developed by Ewin Labs that harnesses the power of Large Language Models (LLMs). Ewin Chat is designed to bridge the gap between open-source LLMs and broad accessibility, offering a cutting-edge chat experience that is both innovative and freely available. Our application transforms how users interact with advanced AI, providing seamless communication and exceptional performance. Ewin Chat embodies Ewin Labs' commitment to making sophisticated AI technology accessible to everyone.",
      technologies: ["Python Flask", "React Native","MongoDB","Ollama"]
    },
    {
      title: "UNIT_ED - AI-Driven Learning Platform",
      description: "A premier learning solution from Ewin Labs designed to transform education through advanced AI technology. Our holistic, web-based platform delivers an immersive educational experience with interactive 3D models, a diverse array of learning resources, and seamless integration of student data for personalized, self-paced learning. UNIT_ED features discreetly embedded evaluative chatbot functionality, offering tailored guidance and feedback to enhance student engagement and optimize learning outcomes.",
      technologies: [ "React", "Node.js","MongoDB","Ollama","Sketchfab"]
    },
  ];

  return (
    <Container id="project">
      <h2>Our Products</h2>
      <div className="projects">
        {projects.map((project, index) => (
          <ScrollAnimation key={index} animateIn="fadeInUp">
            <div className="project">
              <header>
                <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#17726d" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg> 
              </header>
              <div className="body">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
              {/* <footer>
                <ul className="tech-list">
                  {project.technologies.map((tech, i) => (
                    <li key={i}>{tech}</li>
                  ))}
                </ul>
              </footer>*/}
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </Container>
  );
}
