import { Container } from "./styles";
import Farith from "../../assets/logo_white.png";
import mongodb from "../../assets/mongodb.svg";
import htmlIcon from "../../assets/html-icon.svg";
import cssIcon from "../../assets/css-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";
import Python from "../../assets/python1.svg";
import opencv from "../../assets/opencv.svg";
import tensorflow from "../../assets/tensorflow.svg"
import mysql from '../../assets/mysql-icon.svg'
import docker from '../../assets/docker-icon.svg'
import github from '../../assets/github-mark-white.svg'

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About Us</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
          Ewin Labs, where innovation and technology converge to create solutions that drive progress and excellence. Founded with a vision to harness the power of advanced AI and cutting-edge technologies, Ewin Labs is dedicated to developing transformative products that enhance various aspects of everyday life and business.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Our Mission</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>
          At Ewin Labs, our mission is to empower individuals and organizations with intelligent technology solutions that are both advanced and user-friendly. We bridge the gap between complex tech and practical applications, pushing the boundaries of innovation to deliver impactful solutions.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Our Vision</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
          Our vision is to lead in technological innovation, creating solutions that inspire and transform. We aim to enhance human capabilities and drive progress, envisioning a future where our technologies optimize processes and open new possibilities for global impact.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Our Values</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <p>
          At Ewin Labs, our foremost value is our commitment to our clients. We believe that innovation, accessibility, and excellence are only meaningful when they serve the needs of those we aim to help. We are dedicated to pioneering technologies that not only set new standards and push boundaries but also make advanced solutions accessible to everyone. By prioritizing our customers' needs and delivering high-quality products, we strive to exceed expectations and make a positive impact on their lives and work.</p>
        </ScrollAnimation>
       {/*  <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Here are my main skills:</h3>
        </ScrollAnimation>
       <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.10 * 1000}>
              <img src={Python} alt="Python" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.15 * 1000}>
              <img src={opencv} alt="OpenCV" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.15 * 1000}>
              <img src={tensorflow} alt="Tensorflow" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <img src={reactIcon} alt="React" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
              <img src={typescriptIcon} alt="Typescript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.17 * 1000}>
              <img src={htmlIcon} alt="Html" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.18 * 1000}>
              <img src={cssIcon} alt="Css" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
              <img src={jsIcon} alt="JavaScript" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.19 * 1000}>
              <img src={mysql} alt="MySQL" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.16 * 1000}>
              <img src={nodeIcon} alt="Node" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.12 * 1000}>
              <img src={mongodb} alt="mongodb" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.12 * 1000}>
              <img src={docker} alt="docker" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.12 * 1000}>
              <img src={github} alt="github" />
            </ScrollAnimation>
          </div>
        </div>*/}
      </div>
     {/* <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          <img src={Farith} alt="Abdul farith" />
        </ScrollAnimation>
      </div>*/}
    </Container>
  )
}
